import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { renderRoutes } from 'react-router-config';
import { ConnectedRouter } from 'connected-react-router';

import 'normalize.css';
import './global.css';

import store, { history } from 'models/store';
import routes from 'routes';

const addObserverPollfill = async () => {
    if (!window.ResizeObserver) {
        window.ResizeObserver = (await import('resize-observer-polyfill')).default;
    }
};

addObserverPollfill();

ReactDOM.render(
    <Provider store={store}>
        <ConnectedRouter history={history}>{renderRoutes(routes)}</ConnectedRouter>
    </Provider>,
    document.getElementById('app'),
);
