import bgm from 'audio/bgm.mp3';
import bgImage from 'images/brand/intro-bg.jpg';

import type { Intro as MenuIntro } from 'components/molecules/Menu';

import type { ImageAlign, TourData } from 'utils/types/customization';

interface ModelConfig {
    tools: {
        info: boolean;
        infoDefaultValue?: boolean;
        position: boolean;
        positionDefaultValue?: boolean;
        highlight: boolean;
        tour: boolean;
        view: boolean;
        floors: boolean;
        share: boolean;
        screenshot: boolean;
        getLocation: boolean;
    };
    menu: boolean;
    music?: string;
    intro?: MenuIntro[];
    displayTourList?: string[];
    defaultTour: string;
    defaultTitle: string;
    tourData: {
        [key: string]: TourData;
    };
    fixedLink: {
        text: string;
        url: string;
    };
}

const modelConfig: ModelConfig = {
    tools: {
        info: true,
        infoDefaultValue: true,
        position: true,
        positionDefaultValue: false,
        highlight: true,
        tour: true,
        view: true,
        floors: false,
        share: true,
        screenshot: false,
        getLocation: false,
    },
    music: bgm,
    menu: true,
    intro: [
        {
            type: 'ZH',
            content: `淨空老法師，法名覺淨，字淨空，1927年出生於中國安徽省廬江縣，俗名徐業鴻。1953年先後追隨一代大哲桐城方東美教授、藏傳高僧章嘉呼圖克圖與儒佛大家濟南李炳南老教授，學習經史哲學以及佛法十三年，於佛教淨土宗著力最多。其一生對佛陀教育的推廣，貢獻良多。

二十世紀英國歷史學家湯恩比博士曾說：「欲解決二十一世紀的社會問題，唯有孔孟學說與大乘佛法。」淨空老法師每天講經說法從無間斷超過六十年，留下了大量的錄音、影像資料，並透過網路媒體傳播到全世界，老法師的講經弘法以儒釋道為主並蘊含著多元文化的教育理念，其一生致力於宣揚倫理道德教育，以「團結宗教、族群為世界和平的基礎」之理念，深深感動了全世界無數的仁人志士，期望天下人皆能持有仁慈博愛之精神，落實和諧社會的大同理想，並為這個世界帶來正的能量。

淨空老法師為世界宗教領袖、佛教淨土宗一代宗師，本展透過老法師的墨寶、手稿、文獻、信札、文房器用等珍貴文物，向大眾展示其對世界宗教和平的貢獻及對佛教的深遠影響。`,
        },
    ],

    displayTourList: [],
    defaultTour: 'main',
    defaultTitle: '覺光遠照 淨空老法師文物展',
    tourData: {
        main: {
            text: '覺光遠照 淨空老法師文物展',
            path: '2013',
            title: '覺光遠照 淨空老法師文物展',
            cover: bgImage,

            modelId: 'HXGfFhwM6P9',
            sr: '.11,.96',
            ss: '8',

            sweepId: 'fd3aaf421a854427bb1a938abd68193b',
            position: { x: 3.5167922973632812, y: 1.4858978986740112, z: 2.863723039627075 },
            rotation: { x: 3.6097326127099105, y: 55.16696692572134 },
        },
    },
    fixedLink: {
        text: '華藏淨宗弘化網',
        url: 'https://www.hwadzan.com/',
    },
};

export const imageAlignConfig: {
    default: ImageAlign;
    exceptions: {
        [key: string]: ImageAlign;
    };
} = {
    default: 'center',
    exceptions: {},
};

export default modelConfig;
