import { useHistory } from 'react-router-dom';

const NotFonudLayout: React.FC = () => {
    const history = useHistory();
    history.push('/');

    return <></>;
};

export default NotFonudLayout;
