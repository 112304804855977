import { Suspense } from 'react';
import { renderRoutes } from 'react-router-config';

import type { RouteConfigComponentProps } from 'react-router-config';

const App = ({ route }: RouteConfigComponentProps) => (
    <Suspense fallback={<></>}>
        {/* child routes won't render without this */}
        {renderRoutes(route && route.routes)}
    </Suspense>
);

export default App;
