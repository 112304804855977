import { createReducer } from '@reduxjs/toolkit';

import { actionA, actionB, actionC } from './actions';

export interface State {
    value: number;
}

const initialState: State = {
    value: 0,
};

const reducer = createReducer(initialState, (builder) => {
    builder
        .addCase(actionA, (state, action) => {
            state.value += action.payload;
        })
        .addCase(actionB, (state, action) => {
            return {
                ...state,
                value: state.value + action.payload,
            };
        })
        .addCase(actionC.fulfilled, (state, action) => {
            state.value += action.payload;
        });
});

export default reducer;
