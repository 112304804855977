import { lazy } from 'react';

// import notFoundRoutes from 'routes/NotFound';

import modelConfig from 'exhibition.config';

const { tourData, displayTourList } = modelConfig;

const tourPaths =
    displayTourList?.filter((key) => tourData[key].path).map((key) => tourData[key].path) || [];

export default [
    {
        path: ['/', `/:tour(${tourPaths.join('|')})`],
        exact: true,
        component: lazy(() => import(/* webpackChunkName: 'HomeLayout' */ './layout')),
    },
];
