import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';

import globalReducer from 'models/global/reducer';

import type { History } from 'history';

import type { State as GlobalState } from 'models/global/reducer';

export interface State {
    global: GlobalState;
}

const createRootReducer = (history: History) =>
    combineReducers({
        router: connectRouter(history),
        global: globalReducer,
    });

export default createRootReducer;
