import { createAction, createAsyncThunk } from '@reduxjs/toolkit';

export const actionA = createAction<number>('global/actionA');

export const actionB = createAction('global/actionB', (value: number) => {
    return {
        payload: value,
    };
});

export const actionC = createAsyncThunk<number>(
    'global/actionC',
    /* eslint-disable @typescript-eslint/no-unused-vars */
    // @ts-ignore 6918
    async (value, { dispatch, getState }) => {
        return value;
    },
    /* eslint-enable @typescript-eslint/no-unused-vars */
);
