// ref: https://github.com/DefinitelyTyped/DefinitelyTyped/blob/master/types/react-router-config/react-router-config-tests.tsx

import type { RouteConfig } from 'react-router-config';
import notFoundRoutes from 'routes/NotFound';

import App from './layout';

import homeRoutes from './Home';

const routes: RouteConfig[] = [
    {
        component: App,
        routes: [...homeRoutes, ...notFoundRoutes],
    },
];

export default routes;
